import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@components/layout/layout'
import SEO from '@components/shared/seo'
import Container from '@components/shared/container'
import TeamCard from '@components/pages/about/TeamCard'
import seoMeta from '@seo-data/team'

const AboutPage = ({ data }) => {
  const team = data.mates.edges

  return (
    <Layout>
      <SEO data={seoMeta} />
      <Container>
        <section className="team page-padding">
          {team.map((teammate, index) =>
            <TeamCard key={index} data={teammate.node}/>
          )}
        </section>
      </Container>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
query {
  mates : allTeamJson {
    edges {
      node {
        id
        firstName
        description
        subDescription
        avatar
        history {
          date
          facts
        }
      }
    }
  }
}
`
