const seoData = {
  "openGraph": {
    "id": "equipe",
    "title": "Notre équipe",
    "description": "Retrouvez notre équipe de spécialiste du web (Drupal, ReactJS, Angular, etc...)",
  },
  "microdata": {
    "@context": "http://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "name": "Note équipe",
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "item": "https://www.acolad.fr/",
              "name": "Accueil"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "item": "https://www.acolad.fr/equipe",
              "name": "L'équipe"
            }
          ]
        }
      },
      {
        "@type": "Person",
        "@id": "https://www.acolad.fr/equipe#raph",
        "name": "Raphael Apard",
        "jobTitle": "Developer",
        "email": "raphael.apard@acolad.fr",
        "gender": "Male",
        "sameAs": [
          'https://www.linkedin.com/in/raphael-apard-81b90924/',
          'https://www.drupal.org/u/raphael-apard'
        ]
      },
      {
        "@type": "Person",
        "@id": "https://www.acolad.fr/equipe#celia",
        "name": "Célia Chazel",
        "jobTitle": "Developer",
        "email": "celia.chazel@acolad.fr",
        "gender": "Female",
        "sameAs": 'https://www.linkedin.com/in/ce'
      }
    ],
  }
}

export default seoData
