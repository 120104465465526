import React, { useState } from 'react'

const TeamCard = props => {
  const teamMate = props.data
  const [showHistory, setShowHistory] = useState(0)

  return (
    <article className="teammate" id={teamMate.id}>
      <header className="teammate__header">
        <div className="teammate__header__avatar">
          <img src={require('../../../assets/images/' + teamMate.avatar)} alt={'La tête de ' + teamMate.firstName + ' dessinée'}/>
        </div>
        <div>
          <h1 className="teammate__header__name">{teamMate.firstName} {teamMate.lastName}</h1>
          <p className="teammate__header__description">{teamMate.description}</p>
          <p className="teammate__header__sub-description">{teamMate.subDescription}</p>
          <button className={`teammate__header__btn ${ showHistory ? 'down' : '' }`} onClick={() => setShowHistory(!showHistory) }>
            Voir le CV
          </button>
        </div>
      </header>
      <ul className={`teammate__history ${ showHistory ? 'teammate__history--open' : '' }`}>
        {teamMate.history.map((li, index) =>
          <li key={index}>
            <span className="teammate__history__dates">{li.date}</span>
            <span className="teammate__history__facts" dangerouslySetInnerHTML={{ __html: li.facts }}></span>
          </li>)}
      </ul>
    </article>
  )
}

export default TeamCard
